html {
  --blanco: rgba(255, 255, 255, 1);
  --negro: rgba(0, 0, 0, 1);
  --grisClaro: rgba(242, 242, 242, 1);
  --magentaBase: rgba(231, 29, 115, 1);
  --grisBase: rgba(155, 154, 154, 1);
  --cyanBase: rgba(8, 240, 252, 1);
  --grisInter: rgba(198, 198, 198, 1);
  --grisFondo: rgba(252, 252, 252, 1);
  --bold: 700;
  --medium: 500;
  --regular: 400;
  --light: 300;
  --thin: 100;
  --h1: 2.488rem;
  --h2: 2.074rem;
  --h3: 1.728rem;
  --h4: 1.44rem;
  --h5: 1.2rem;
  --h6: var(--p);
  --p: 16px;
  --info: 12px;
  background-color: var(--negro);
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: var(--negro);
}

p {
  font-size: var(--p);
  font-weight: var(--regular);
}

h1 {
  font-size: var(--h1);
  font-weight: var(--bold);
}

h2 {
  font-size: var(--h2);
  font-weight: var(--regular);
}

h3 {
  font-size: var(--h3);
  font-weight: var(--regular);
}

h4 {
  font-size: var(--h4);
  font-weight: var(--regular);
}

h5 {
  font-size: var(--h5);
  font-weight: var(--regular);
}

h6 {
  font-size: var(--h6);
  font-weight: var(--bold);
}

body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--blanco);
  font-family: "Be Vietnam Pro", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  line-height: 1;
}

.logo {
  width: 100px;
  height: auto;
}
