.home {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
}

.homeViewHeightContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.logoLobas {
  position: absolute;
  width: 380px;
  height: auto;
  bottom: 40%;
  right: auto;
  z-index: 2;
}

.homeWallpaper {
  position: absolute;
  width: 100%;
  height: auto;
  z-index: 1;
  top: 0;
  right: 0;
  opacity: 0.5;
}

.scrollDownContainer {
  display: flex;
  flex-direction: column;
  z-index: 2;
  position: absolute;
  bottom: 45px;
  right: auto;
}

#pathScrollDown {
  animation: downMove 1s ease-out infinite;
}

@keyframes downMove {
  from {
    transform: translate(0px, 0px);
    opacity: 1;
  }
  to {
    transform: translate(0px, 20px);
    opacity: 0;
  }
}

.scrollUpContainer {
  position: fixed;
  z-index: 100;
  top: 50%;
  right: 28px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  height: auto;
}

.scrollUpNegro {
  color: var(--negro);
  stroke: var(--negro);
}

.scrollUpMagenta {
  color: var(--magentaBase);
  stroke: var(--magentaBase);
}

.scrollUpCyan {
  color: var(--cyanBase);
  stroke: var(--cyanBase);
}

.blockServices {
  display: flex;
  width: 100%;
  height: 425px;
  padding: 50px 136px 50px 136px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  position: relative;
}

.blockServices__title {
  display: flex;
  height: auto;
  flex-direction: column;
  flex-shrink: 0;
  align-self: stretch;
  font-size: 3.76rem;
}

.blockServices__subtitleContainer {
  display: flex;
  flex-direction: column;
  left: 142px;
  height: 100%;
  justify-content: center;
  gap: 40px;
}

.blockServices__subtitle {
  line-height: 1;
  font-size: 1.5rem;
  font-weight: var(--light);
}

.blockServices--com {
  background: var(--magentaBase);
}

.subtitleContainer--com {
  width: auto;
  bottom: 125px;
}

.blockServices__subtitle--com {
  color: var(--blanco);
}

.blockServices--branding {
  background: var(--grisBase);
}

.blockServices__title--branding {
  color: var(--magentaBase);
}

.subtitleContainer--branding {
  width: auto;
  bottom: 135px;
}

.blockServices__title--digital {
  color: var(--cyanBase);
}

.subtitleContainer--digital {
  width: auto;
  bottom: 135px;
}

.copyright {
  font-size: var(--info);
}

@media screen and (max-width: 1200px) {
  .scrollUpContainer,
  .scrollUpNegro,
  .scrollUpMagenta,
  .scrollUpCyan,
  .scrollUp,
  .scrollTextUp {
    display: none;
  }

  /* .scrollDownContainer {
    display: none;
  } */

  .blockServices {
    width: 100%;
    height: auto;
    padding: 50px 10px 50px 10px;
  }

  .blockServices__title {
    font-size: var(--h1);
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 480px) {
  .homeViewHeightContainer {
    justify-content: center;
  }

  .homeWallpaper {
    height: 100vh;
    opacity: 0.9;
    object-fit: cover;
  }

  .logoLobas {
    display: none;
  }
}
