.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 24px;
}

@media screen and (max-width: 1200px) {
  .App {
    padding: 0;
    width: 100vw;
  }
}
