.navbar {
  display: flex;
  width: 100%;
  height: 109px;
  justify-content: flex-end;
  align-items: flex-start;
  flex-shrink: 0;
  z-index: 2;
  padding: 45px;
}

.navbar__logo {
  width: 210px;
  height: auto;
  display: none;
}

.navbar__linksContainer {
  display: flex;
  width: auto;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
}

.linksContainer__link {
  display: flex;
  padding: 5px 10px;
  align-items: flex-start;
  gap: 10px;
  font-size: 1.5rem;
  text-align: center;
  color: var(--negro);
}

.linksContainer__link:hover {
  color: var(--magentaBase);
}

@media screen and (max-width: 1135px) {
  .navbar {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    gap: 50px;
    padding: 10px;
  }

  .navbar__linksContainer {
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
  }
}

@media screen and (max-width: 480px) {
  .navbar {
    gap: 50px;
  }

  .navbar__logo {
    display: unset;
  }

  .navbar__linksContainer {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
}
